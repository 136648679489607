<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose-Score" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop-Score" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div
      class="dropdown"
      :class="{ 'dropdown--open': abreBloco }"
      v-if="resultados != null"
    >
      <HeaderBlocos :bloco="blocoScore" />
      <div class="body" :class="{ 'body--aberto': abreBloco }">
        <div class="score-box">
          <div class="col-score sb-4">
            <div class="col-title">Divisão de Books</div>
            <div class="row-icons">
              <img :src="iconPF" alt="" class="icon-div pf" id="icon-pf" />
              <b-tooltip
                v-if="exibirTootipsConsultas"
                target="icon-pf"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tt-text"
                boundary="window"
              >
                Pessoa Física
              </b-tooltip>
              <img :src="iconPJ" alt="" class="icon-div pj" id="icon-pj" />
              <b-tooltip
                v-if="exibirTootipsConsultas"
                target="icon-pj"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tt-text"
                boundary="window"
              >
                Pessoa Jurídica
              </b-tooltip>
              <img :src="iconGEO" alt="" class="icon-div geo" id="icon-geo" />
              <b-tooltip
                v-if="exibirTootipsConsultas"
                target="icon-geo"
                triggers="hover"
                placement="bottom"
                variant="dark"
                class="tt-text"
                boundary="window"
              >
                Geolocalidade
              </b-tooltip>
            </div>
            <div class="info-text pf" id="info-pf">
              Reúne <br />
              um conjunto de dados que caracterizam o perfil social e econômico
              do indivíduo.
            </div>
            <b-tooltip
              v-if="exibirTootipsConsultas"
              target="info-pf"
              triggers="hover"
              placement="bottom"
              variant="dark"
              class="tt-text"
              boundary="window"
            >
              Descrição sobre os books PF
            </b-tooltip>
            <div class="info-text pj" id="info-pj">
              Uma <br />
              ampla gama de atributos sobre o histórico de empresas, ativas ou
              não, ligadas ao indivíduo avaliado.
            </div>
            <b-tooltip
              v-if="exibirTootipsConsultas"
              target="info-pj"
              triggers="hover"
              placement="bottom"
              variant="dark"
              class="tt-text"
              boundary="window"
            >
              Descrição sobre os books PJ
            </b-tooltip>
            <div class="info-text geo" id="info-geo">
              Mapeamento detalhado das particularidades do entorno vinculado ao
              documento, combinando avaliações socioeconômicas, infraestrutura,
              além de dados das empresas da região.
            </div>
            <b-tooltip
              v-if="exibirTootipsConsultas"
              target="info-geo"
              triggers="hover"
              placement="bottom"
              variant="dark"
              class="tt-text"
              boundary="window"
            >
              Descrição sobre os books GEO
            </b-tooltip>
          </div>
          <div class="col-score sb-4 center">
            <div class="value-box">
              <span class="value-title"> Score </span>
              <span class="value-text">
                {{ modeloSelecionado.Valor }}
              </span>
            </div>
            <div class="results-box">
              <div class="col-score sb-2">
                <span class="result-title" :id="'BScore-descricao'">
                  Descrição
                </span>
                <span class="result-value big"
                  >{{ modeloSelecionado.Descricao }}
                </span>
              </div>
              <div class="col-score sb-1">
                <span class="result-title text-center">Origem</span>
                <span class="result-value big">
                  <span class="box-valores" :id="'BScore-origem-C'">
                    <img
                      class="icon-valor left"
                      src="/img/superbox/mapa-riscos-prat-cinza.png"
                    />
                    <img
                      class="icon-valor"
                      src="/img/superbox/mapa-riscos-cust.png"
                    />
                  </span>
                </span>
              </div>
            </div>
            <div class="renda-box" id="renda-valor">
              <span
                class="renda-text"
                v-if="resultados.renda_presumida != undefined"
                :class="{
                  'sem-legenda': legendaValor(resultados.renda_presumida) == '',
                }"
              >
                {{ formatarValorParaReais(resultados.renda_presumida) }}
                <b-tooltip
                  v-if="exibirTootipsConsultas"
                  target="renda-valor"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                  class="tt-text"
                  boundary="window"
                >
                  R$ {{ formatarNumeroComPonto(resultados.renda_presumida) }},00
                </b-tooltip>
              </span>
              <span
                class="renda-text"
                id="faturamento-valor"
                v-if="resultados.faturamento_presumido != undefined"
                :class="{
                  'sem-legenda':
                    legendaValor(resultados.faturamento_presumido) == '',
                }"
              >
                {{ formatarValorParaReais(resultados.faturamento_presumido) }}
                <b-tooltip
                  v-if="exibirTootipsConsultas"
                  target="renda-valor"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                  class="tt-text"
                  boundary="window"
                >
                  R$
                  {{
                    formatarNumeroComPonto(resultados.faturamento_presumido)
                  }},00
                </b-tooltip>
              </span>
              <span
                class="legenda"
                v-if="resultados.renda_presumida != undefined"
              >
                {{ legendaValor(resultados.renda_presumida) }}
              </span>
              <span
                class="legenda"
                v-if="resultados.faturamento_presumido != undefined"
              >
                {{ legendaValor(resultados.faturamento_presumido) }}
              </span>
            </div>
            <div
              class="text-box"
              v-if="resultados.renda_presumida != undefined"
            >
              Os modelos OutBox de valores trazem estimativas
              <span class="dest">pontuais</span>
              , apoiados sobre a <span class="dest">ampla</span> gama
              <span class="dest">atributos</span>, dividindo-se entre
              <span class="dest">informações</span> sobre Pessoas, Empresas e
              Geolocalidade.
            </div>

            <div
              class="text-box"
              v-if="resultados.faturamento_presumido != undefined"
            >
              <span v-if="resultados.faturamento_presumido == -1">
                {{ resultados.elegibilidade }}
              </span>
              <span v-else>
                Os modelos OutBox de valores trazem estimativas
                <span class="dest">pontuais</span>
                , apoiados sobre a <span class="dest">ampla</span> gama
                <span class="dest">atributos</span>, dividindo-se entre
                <span class="dest">informações</span> sobre Pessoas, Empresas e
                Geolocalidade.
              </span>
            </div>
          </div>
          <div class="col-score end sb-4">
            <div class="col-title right">Fontes em Destaque</div>
            <div class="logos-box">
              <div class="logos-row">
                <img
                  @mouseover="alteraImagem('receita_federal', true)"
                  @mouseleave="alteraImagem('receita_federal', false)"
                  :src="getSrc('receita_federal')"
                  ref="receita_federal"
                  id="receita_federal"
                  alt=""
                  class="logos-fontes"
                />
                <img
                  @mouseover="alteraImagem('ibge', true)"
                  @mouseleave="alteraImagem('ibge', false)"
                  :src="getSrc('ibge')"
                  ref="ibge"
                  id="ibge"
                  alt=""
                  class="logos-fontes"
                  style="top: 20px"
                />
                <img
                  @mouseover="alteraImagem('bacen', true)"
                  @mouseleave="alteraImagem('bacen', false)"
                  :src="getSrc('bacen')"
                  ref="bacen"
                  id="bacen"
                  alt=""
                  class="logos-fontes"
                />
                <img
                  @mouseover="alteraImagem('correios', true)"
                  @mouseleave="alteraImagem('correios', false)"
                  :src="getSrc('correios')"
                  ref="correios"
                  id="correios"
                  alt=""
                  class="logos-fontes"
                  style="top: 20px"
                />
              </div>
              <div class="logos-row pt-4">
                <img
                  @mouseover="alteraImagem('pnad', true)"
                  @mouseleave="alteraImagem('pnad', false)"
                  :src="getSrc('pnad')"
                  ref="pnad"
                  id="pnad"
                  alt=""
                  class="logos-fontes"
                />
                <img
                  @mouseover="alteraImagem('ipea', true)"
                  @mouseleave="alteraImagem('ipea', false)"
                  :src="getSrc('ipea')"
                  ref="ipea"
                  id="ipea"
                  alt=""
                  class="logos-fontes"
                  style="margin: 0 16px; top: 10px; left: 5px"
                />
                <img
                  @mouseover="alteraImagem('tcu', true)"
                  @mouseleave="alteraImagem('tcu', false)"
                  :src="getSrc('tcu')"
                  ref="tcu"
                  id="tcu"
                  alt=""
                  class="logos-fontes"
                />
              </div>
              <div class="logos-row" style="padding: 15px 0 0 0">
                <img
                  @mouseover="alteraImagem('portal_transparencia', true)"
                  @mouseleave="alteraImagem('portal_transparencia', false)"
                  :src="getSrc('portal_transparencia')"
                  ref="portal_transparencia"
                  id="portal_transparencia"
                  alt=""
                  class="logos-fontes"
                  style="height: 35px; max-width: 175px"
                />
                <img
                  @mouseover="alteraImagem('susep', true)"
                  @mouseleave="alteraImagem('susep', false)"
                  :src="getSrc('susep')"
                  ref="susep"
                  id="susep"
                  alt=""
                  class="logos-fontes"
                  style="top: 10px; right: 3px"
                />
                <img
                  @mouseover="alteraImagem('incra', true)"
                  @mouseleave="alteraImagem('incra', false)"
                  :src="getSrc('incra')"
                  ref="incra"
                  id="incra"
                  alt=""
                  class="logos-fontes"
                  style="width: 40px; margin: 0 5px"
                />
              </div>
              <div class="logos-row pt-4">
                <img
                  @mouseover="alteraImagem('seguro_rural', true)"
                  @mouseleave="alteraImagem('seguro_rural', false)"
                  :src="getSrc('seguro_rural')"
                  ref="seguro_rural"
                  id="seguro_rural"
                  alt=""
                  class="logos-fontes"
                  style="height: 25px; max-width: 175px; bottom: 13px"
                />
                <img
                  @mouseover="alteraImagem('bndes', true)"
                  @mouseleave="alteraImagem('bndes', false)"
                  :src="getSrc('bndes')"
                  ref="bndes"
                  id="bndes"
                  alt=""
                  class="logos-fontes"
                  style="margin: 0 15px"
                />
                <img
                  @mouseover="alteraImagem('anp', true)"
                  @mouseleave="alteraImagem('anp', false)"
                  :src="getSrc('anp')"
                  ref="anp"
                  id="anp"
                  alt=""
                  class="logos-fontes"
                />
              </div>
              <div class="logos-row" style="padding: 36px 0 0 0">
                <img
                  @mouseover="alteraImagem('sicar', true)"
                  @mouseleave="alteraImagem('sicar', false)"
                  :src="getSrc('sicar')"
                  ref="sicar"
                  id="sicar"
                  alt=""
                  class="logos-fontes"
                  style="
                    height: 20px;
                    max-width: 175px;
                    bottom: 35px;
                    right: 40px;
                  "
                />
              </div>
              <div class="logos-row" style="padding: 28px 0 0 0">
                <img
                  @mouseover="alteraImagem('portal_gov', true)"
                  @mouseleave="alteraImagem('portal_gov', false)"
                  :src="getSrc('portal_gov')"
                  ref="portal_gov"
                  id="portal_gov"
                  alt=""
                  class="logos-fontes"
                  style="
                    height: 20px;
                    max-width: 175px;
                    bottom: 50px;
                    right: 20px;
                  "
                />
                <img
                  @mouseover="alteraImagem('pncf', true)"
                  @mouseleave="alteraImagem('pncf', false)"
                  :src="getSrc('pncf')"
                  ref="pncf"
                  id="pncf"
                  alt=""
                  class="logos-fontes"
                  style="bottom: 70px; max-width: 82px; left: 35px"
                />
              </div>
              <div class="logos-row" style="padding: 16px 0 0 0">
                <img
                  @mouseover="alteraImagem('inep', true)"
                  @mouseleave="alteraImagem('inep', false)"
                  :src="getSrc('inep')"
                  ref="inep"
                  id="inep"
                  alt=""
                  class="logos-fontes"
                  style="height: 20px; max-width: 175px; bottom: 65px"
                />
                <img
                  @mouseover="alteraImagem('cnpo', true)"
                  @mouseleave="alteraImagem('cnpo', false)"
                  :src="getSrc('cnpo')"
                  ref="cnpo"
                  id="cnpo"
                  alt=""
                  class="logos-fontes"
                  style="bottom: 73px; max-width: 82px; left: 17px"
                />
              </div>
              <div v-for="key in Object.keys(fontesDestaque)" :key="key">
                <b-tooltip
                  v-if="exibirTootipsConsultas"
                  :target="key"
                  triggers="hover"
                  placement="top"
                  variant="dark"
                  class="tt-text"
                  boundary="window"
                >
                  <div v-html="fontesDestaque[key].descricao"></div>
                </b-tooltip>
              </div>
              <div v-for="tt in tooltipsBlocoRendaFiltrado" :key="tt.target">
                <b-tooltip
                  v-if="exibirTootipsConsultas"
                  :target="tt.target"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                  class="tooltipTexts"
                  boundary="window"
                >
                  <div v-html="tt.texto"></div>
                </b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapState, mapActions } from "pinia";
import HeaderBlocos from "@/components/Modelos/HeaderBlocos.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import fontesDestaque from "@/helpers/fontes.json";

export default {
  name: "BlocoRenda",

  components: {
    HeaderBlocos,
  },

  props: {
    listaBlocos: Array,
    resultados: Object,
    modeloSelecionado: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
      iconPF: "/img/modelos/Icones-PF.png",
      iconPJ: "/img/modelos/Icones-PJ.png",
      iconGEO: "/img/modelos/Icones-GEO.png",
    };
  },

  methods: {
    alteraImagem(chave, valor) {
      this.$set(this.fontesDestaque[chave], "hover", valor);
      this.$refs[chave].src = this.fontesDestaque[chave].hover
        ? this.fontesDestaque[chave].original
        : this.fontesDestaque[chave].azul;
    },

    getSrc(chave) {
      return this.fontesDestaque[chave].hover
        ? this.fontesDestaque[chave].original
        : this.fontesDestaque[chave].azul;
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    formatarValorParaReais(valor) {
      if (valor != -1) {
        if (Math.abs(valor) >= 1000000000) {
          const valorEmBilhoes = (valor / 1000000000).toFixed(2);
          if (parseFloat(valorEmBilhoes) >= 2) {
            return `${parseFloat(valorEmBilhoes).toLocaleString("pt-BR")}`;
          } else {
            return `${parseFloat(valorEmBilhoes).toLocaleString("pt-BR")}`;
          }
        } else if (Math.abs(valor) >= 1000000) {
          const valorEmMilhoes = (valor / 1000000).toFixed(2);
          if (parseFloat(valorEmMilhoes) >= 2) {
            return `${parseFloat(valorEmMilhoes).toLocaleString("pt-BR")}`;
          } else {
            return `${parseFloat(valorEmMilhoes).toLocaleString("pt-BR")}`;
          }
        } else {
          return `${parseFloat(valor)
            .toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
        }
      } else {
        return valor;
      }
    },

    legendaValor(valor) {
      if (valor != -1) {
        if (Math.abs(valor) >= 1000000000) {
          const valorEmBilhoes = (valor / 1000000000).toFixed(2);
          if (parseFloat(valorEmBilhoes) >= 2) {
            return "bilhões";
          } else {
            return "bilhão";
          }
        } else if (Math.abs(valor) >= 1000000) {
          const valorEmMilhoes = (valor / 1000000).toFixed(2);
          if (parseFloat(valorEmMilhoes) >= 2) {
            return "milhões";
          } else {
            return "milhão";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    fontesDestaque() {
      return fontesDestaque;
    },

    blocoScore() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "Valores estimados"
      );
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    tooltipsBlocoScore() {
      return listaTooltips.blocoScore;
    },

    tooltipsBlocoRendaFiltrado() {
      return listaTooltips.blocoScore.filter((tt) => {
        return (
          tt.target === "BScore-descricao" || tt.target === "BScore-origem-C"
        );
      });
    },

    valorFaixaScore() {
      const score = this.resultados.score;

      if (score >= 0 && score <= 100) {
        return "0 e 100";
      } else if (score > 100 && score <= 200) {
        return "101 e 200";
      } else if (score > 200 && score <= 300) {
        return "201 e 300";
      } else if (score > 300 && score <= 400) {
        return "301 e 400";
      } else if (score > 400 && score <= 500) {
        return "401 e 500";
      } else if (score > 500 && score <= 600) {
        return "501 e 600";
      } else if (score > 600 && score <= 700) {
        return "601 e 700";
      } else if (score > 700 && score <= 800) {
        return "701 e 800";
      } else if (score > 800 && score <= 900) {
        return "801 e 900";
      } else if (score > 900 && score <= 1000) {
        return "901 e 1000";
      } else {
        return "";
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.score-box {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  cursor: default;
  background-image: url(/img/modelos/modelos-renda-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  height: 390px;
}

.col-score {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &.end {
    align-items: flex-end;
  }

  &.center {
    justify-content: flex-start;
    align-items: center;
  }
}

.col-title {
  color: #0070c0;
  text-align: center;
  width: 260px;
  padding: 10px 0 0 0;

  &.right {
    padding: 10px 5px 0 0;
  }
}

.row-icons {
  .icon-div {
    width: 27px;
    position: relative;
    z-index: 2;
    transition: 0.3s;

    &.pf {
      left: 57px;
      top: 60px;
    }
    &.pj {
      left: 149px;
      top: 60px;
    }
    &.geo {
      left: 63px;
      top: 36px;
    }

    &:hover {
      transform: scale((1.05));
    }
  }
}

.info-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: white;
  position: relative;

  &.pf {
    width: 100px;
    text-align: left;
    top: 94px;
    left: 20px;
  }

  &.pj {
    text-align: right;
    width: 100px;
    bottom: 23px;
    left: 140px;
  }
  &.geo {
    text-align: center;
    width: 215px;
    bottom: 15px;
    left: 20px;
  }
}

.value-box {
  width: 237px;
  display: flex;
  flex-direction: column;
  padding: 13px 3px 10px 0;
  border-bottom: solid 1px #0070c0;

  .value-title {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a69be0;
    border-radius: 30px 30px 0 0;
    color: white;
  }

  .value-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    background-color: white;
    color: #0070c0;
    font-weight: 600;
    border-radius: 0 0 10px 10px;
  }
}

.results-box {
  display: flex;
  padding: 5px 0 10px 0;
  gap: 10px;
  font-size: 12px;
  width: 237px;

  .result-title {
    width: 100%;
    height: 30px;
    background-color: #d9e1f2;
    border-radius: 5px;
    padding: 5px;
    cursor: default;
    text-align: center;
  }

  .result-value {
    width: 100%;
    height: 30px;
    border: 1px solid #0070c0;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;

    &.big {
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
    }

    &.bigger {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    &.classif {
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      padding: 1px 5px;
    }
  }
}

.renda-box {
  position: relative;
  top: 14px;
  color: #0070c0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.renda-text {
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;

  &.sem-legenda {
    margin-top: 9px;
  }
}
.legenda {
  font-size: 16px;
  line-height: 12px;
  text-align: center;
}

.text-box {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  width: 237px;
  position: relative;
  top: 40px;
  color: #0070c0;
  text-align: center;

  .dest {
    font-weight: 500;
  }
}

.logos-box {
  width: 255px;
  display: flex;
  flex-direction: column;
  // gap: 22px;

  .logos-row {
    width: 100%;
    text-align: center;
    position: relative;
    right: 5px;

    .logos-fontes {
      position: relative;
      max-width: 55px;
      max-height: 45px;
      margin: 0 5px 0 5px;
      cursor: default;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.tt-text {
  cursor: default;
  font-size: 12px;
  line-height: 18px;
}
.tt-img {
  max-height: 40px;
  max-width: 150px;
  margin: 10px 5px;
  cursor: default;
}
.box-valores {
  line-height: 35px;
}

.icon-valor {
  &.left {
    margin-right: 10px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
