<template>
  <div class="content">
    <div class="row-info">
      <div class="titulo sb-4 sb-right" :id="'BlocoKYC1-fonte'">Fonte</div>
      <div class="titulo sb-1 sb-right" :id="'BlocoKYC1-cnpj'">
        Retorno da consulta
      </div>
    </div>
    <div class="row-info">
      <div class="results sb-4 sb-right">Tribunal Superior do Trabalho</div>
      <div class="results sb-1 sb-right">
        <Check :valor="Object.keys(this.resultados).length != 1" />
      </div>
      <div
        class="titulo-box sb-2 sb-right"
        :id="'BlocoKYC3-mensagem'"
        v-if="Object.keys(this.resultados).length != 1"
      >
        Mensagem
      </div>
      <div
        class="results sb-5 sb-right"
        v-if="Object.keys(this.resultados).length != 1"
      >
        {{ resultadoTratado.boxconnis003_mensagem }}
      </div>
    </div>
    <div
      class="row-info sb-top2"
      v-if="Object.keys(this.resultados).length != 1"
    >
      <div
        class="titulo-box escuro sb-4 sb-right"
        :id="'BlocoKYC3-informacoes'"
      >
        Informações sobre a CNDT
      </div>
      <div class="titulo-box sb-1 sb-right" :id="'BlocoKYC3-emissao'">
        Emissão da certidão
      </div>
      <div class="titulo-box sb-1 sb-right" :id="'BlocoKYC3-emissao'">
        Flag consta
      </div>
      <div class="titulo-box sb-2 sb-right" :id="'BlocoKYC3-emissao'">
        Data da expedição
      </div>
      <div class="titulo-box sb-3 sb-right" :id="'BlocoKYC3-emissao'">
        Data da emissão da certidão
      </div>
      <div class="titulo-box sb-1 sb-right" :id="'BlocoKYC3-emissao'">Validade</div>
    </div>
    <div class="row-info" v-if="Object.keys(this.resultados).length != 1">
      <div class="titulo-box sb-2 sb-right" :id="'BlocoKYC3-informacoes'">
        Certidão
      </div>
      <div class="results sb-2 sb-right">
        {{ resultadoTratado.boxconnis003_certidao }}
      </div>
      <div class="results sb-1 sb-right">
        <Check
          :valor="resultados.boxconnis003_conseguiu_emitir_certidao_negativa"
        />
      </div>
      <div class="results sb-1 sb-right">
        <Check :valor="resultados.boxconnis003_consta" />
      </div>
      <div class="results sb-2 sb-right">
        {{ resultadoTratado.boxconnis003_expedicao }}
      </div>
      <div class="results sb-3 sb-right">
        {{ formatarData(resultadoTratado.boxconnis003_emissao_data) }}
      </div>
      <div class="results sb-1 sb-right">
        {{ formatarData(resultadoTratado.boxconnis003_validade_data) }}
      </div>
    </div>
    <div
      class="row-info linha-azul"
      v-if="Object.keys(this.resultados).length != 1"
    ></div>
    <div class="row-info" v-if="Object.keys(this.resultados).length != 1">
      <div class="col-info sb-1 sb-right">
        <div class="sb-1" style="height: 95px"></div>
        <div class="titulo-box sb-1" :id="'BlocoKYC3-processos'">
          Existe processos
        </div>
        <div class="results sb-1 sb-top">
          <Check
            :valor="resultadoTratado.boxconnis003_total_de_processos > 0"
          />
        </div>
        <div class="titulo-box sb-1 sb-top" :id="'BlocoKYC3-processos'">
          Total de processos
        </div>
        <div class="results sb-1 sb-top">
          <span v-if="resultadoTratado.boxconnis003_total_de_processos != ''">
            {{ resultadoTratado.boxconnis003_total_de_processos }}
          </span>
          <span v-else> 0 </span>
        </div>
      </div>
      <div class="col-info sb-11">
        <div class="row-info">
          <div class="sb-1"></div>
          <div class="titulo-box escuro expt" :id="'BlocoKYC3-processos'">
            Processos encontrados
          </div>
        </div>
        <!-- <div class="row-info">
          <div class="sb-2"></div>
          <div class="titulo-box sb-3 sb-right" :id="'BlocoKYC3-processos'">
            Número
          </div>
          <div class="titulo-box sb-7" :id="'BlocoKYC3-processos'">
            Mensagem
          </div>
        </div> -->
        <div class="row-info" style="margin: 0">
          <table>
            <thead>
              <tr>
                <th></th>
                <th
                  class="cabec-box sb-1"
                  v-for="header in headerFormatado"
                  :key="header"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in tabelaPaginada" :key="index">
                <td
                  class="cabec-box sb-1"
                  :class="{
                    desativado: row[0] == '-',
                  }"
                >
                  {{ (currentPage - 1) * linhasPorPagina + index + 1 }}
                </td>
                <td
                  class="results-box sb-4"
                  v-for="(value, key) in row"
                  :key="key"
                  :class="{
                    'sb-6': key == 1,
                    'borda-desativada': value == '-',
                  }"
                >
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="row-info sb-10"
      style="align-self: end"
      v-if="Object.keys(this.resultados).length != 1"
    >
      <div class="paginas">
        <span
          class="material-symbols-outlined pag-botao"
          :class="{ disabled: currentPage === 1 }"
          @click="irParaPagina(1)"
        >
          keyboard_double_arrow_left
        </span>

        <span
          class="material-symbols-outlined pag-botao"
          :class="{ disabled: currentPage === 1 }"
          @click="paginaAnterior"
        >
          chevron_left
        </span>

        <span
          class="box-pagina"
          v-for="pagina in paginasVisiveis"
          :key="pagina"
        >
          <button
            @click="irParaPagina(pagina)"
            :class="[
              'botao-pagina',
              { 'pagina-ativa': pagina === currentPage },
            ]"
          ></button>
          <span class="legenda-pagina">{{ pagina }}</span>
        </span>

        <span
          class="material-symbols-outlined pag-botao"
          :class="{ disabled: currentPage === totalPaginas }"
          @click="proximaPagina"
        >
          chevron_right
        </span>

        <span
          class="material-symbols-outlined pag-botao"
          :class="{ disabled: currentPage === totalPaginas }"
          @click="irParaPagina(totalPaginas)"
        >
          keyboard_double_arrow_right
        </span>
      </div>
    </div>
    <div
      class="row-info"
      style="justify-content: center"
      v-if="Object.keys(this.resultados).length == 1"
    >
      <div class="card aviso">
        {{ resultados.msgErro }}
      </div>
    </div>
  </div>

  <!-- <div v-for="(tt, i) in tooltipsInfoPj1" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div> -->
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapWritableState, mapActions } from "pinia";
import Check from "@/components/SuperBox/Check.vue";
import listaTooltipsModelos from "@/helpers/lista_tooltips_modelos.json";

export default {
  name: "BlocoKYCCNDT",

  components: {
    Check,
  },

  props: {
    listaBlocos: Array,
    resultados: Object,
    codigo_agrupador: String,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,

      currentPage: 1,
      linhasPorPagina: 5,
    };
  },

  watch: {},

  methods: {
    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    formatarTexto(texto) {
      if (texto != null && texto != "-") {
        if (this.ocultarDados) {
          texto = texto.replace(/[^ ]/g, "*");
        }
      }

      return texto;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    paginaAnterior() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    proximaPagina() {
      if (this.currentPage < this.totalPaginas) {
        this.currentPage++;
      }
    },

    irParaPagina(pagina) {
      this.currentPage = pagina;
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBoxPJ, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    blocoKYC() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "KYC & Due Diligence"
      );
    },
    headerDicionario() {
      return {
        boxconnis003_lista_codigos_processos: "Número",
        boxconnis003_lista_mensagens_processos: "Mensagem",
      };
    },

    headerFormatado() {
      return Object.keys(this.headerDicionario).map(
        (key) => this.headerDicionario[key]
      );
    },

    resultadosLinhas() {
      const valoresPorLinha = Object.keys(this.headerDicionario)
        .filter((chave) => this.resultadoTratado[chave])
        .map((chave) =>
          this.resultadoTratado[chave]
            .split(",")
            .map((v) => v.replace(/"/g, "").trim())
        );

      const totalColunas = Object.keys(this.headerDicionario).length;
      const totalLinhas = valoresPorLinha[0]?.length || 0;

      if (valoresPorLinha.length === 0) {
        return Array.from({ length: this.linhasPorPagina }, () =>
          Array(totalColunas).fill("-")
        );
      }

      return Array.from({ length: totalLinhas }, (_, rowIndex) =>
        valoresPorLinha.map((coluna) => coluna[rowIndex] || "-")
      );
    },

    tabelaPaginada() {
      const start = (this.currentPage - 1) * this.linhasPorPagina;
      const end = start + this.linhasPorPagina;

      const paginaAtual = this.resultadosLinhas.slice(start, end);

      while (paginaAtual.length < this.linhasPorPagina) {
        paginaAtual.push(
          Array(this.resultadosLinhas[0]?.length || 0).fill("-")
        );
      }

      return paginaAtual;
    },

    totalPaginas() {
      return Math.ceil(this.resultadosLinhas.length / this.linhasPorPagina);
    },

    paginasVisiveis() {
      const total = this.totalPaginas;

      if (total <= 5) {
        return Array.from({ length: total }, (_, i) => i + 1);
      }

      let startPage = Math.max(1, this.currentPage - 2);
      let endPage = Math.min(total, startPage + 4);

      if (endPage === total) {
        startPage = Math.max(1, total - 4);
      }

      return Array.from({ length: 5 }, (_, i) => startPage + i);
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    tooltipsInfoPj1() {
      return listaTooltipsModelos.bloco_info_pj_1;
    },

    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_") &&
          !chave.includes("status_cadastral")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      usuarioLogado: "usuarioLogado",
    }),
  },

  mounted() {},
};
</script>
<style lang="scss" scoped>
.body {
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-info {
  width: 100%;
  margin-top: 5px;
  margin-left: 0px;
  display: flex;
  max-width: 950px;
  justify-content: flex-start;
  cursor: default;

  &.linha-azul {
    border-bottom: 1px dashed #0070c0;
    padding: 5px 0;
  }
}

.aviso {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px;
  width: 80%;
  text-align: center;
  margin: 10px 0 0 0;
  align-self: center;
}

.col-info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cabecTab {
  display: flex;
  width: 100%;
  max-width: 950px;

  line-height: 14px;
}

.cabecText {
  display: flex;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  background-color: #2f75b5;
  color: white;
  @include flex-center(column);
  margin: 20px 0 5px 0;
}

.info-col {
  padding: 0;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.paginas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
}

.titulo {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #0070c0;
  display: flex;
  align-items: flex-end;
  padding: 0 5px;
}

.titulo-box {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  height: 40px;
  text-align: center;

  @include flex-center(column);

  &.todo {
    height: 100%;
  }

  &.escuro {
    background-color: #b4c6e7;
  }

  &.expt {
    width: 783px;
    transform: translateX(8px);
  }
}

table {
  border-spacing: 5px;
  border-collapse: separate;
  transform: translateX(0px);
}

.cabec-box {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  height: 40px;
  text-align: center;

  &.desativado {
    background-color: #d9d9d9 !important;
  }
}

.results-box {
  height: 40px;
  text-align: center;
  border: solid 1px #0070c0;
  border-radius: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 3px;
  word-break: break-word;
  line-height: 12px;

  &.borda-desativada {
    border: solid 1px #999999 !important;
    color: #999999;
  }
}
.results {
  height: 40px;
  text-align: center;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 3px;
  word-break: break-word;
  line-height: 12px;
}

.pag-botao {
  user-select: none;
  color: #0070c0;
  cursor: pointer;
}

.box-pagina {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 11px;
  font-weight: 300;
  line-height: 16px;
  color: #0070c0;
}

.botao-pagina {
  width: 10px;
  height: 10px;
  border: 2px solid #0070c0;
  border-radius: 50%;
  background-color: transparent;
  margin: 0 2px;
  cursor: pointer;
  padding: 0;
}

.botao-pagina.pagina-ativa {
  background-color: #0070c0;
}

.legenda-pagina {
  position: absolute;
  transform: translateY(15px);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
