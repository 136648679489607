<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose-Score" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop-Score" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div class="dropdown" :class="{ 'dropdown--open': abreBloco }">
      <!-- v-if="resultados01.atributos != undefined" -->
      <HeaderBlocos :bloco="blocoKYC" />
      <div class="body" :class="{ 'body--aberto': abreBloco }">
        <div class="cabecTab">
          <span class="cabecText titulo-geral">
            <img
              :src="icones.icone1"
              alt=""
              class="icone-aviso"
              id="icon-web1"
              @mouseover="mudarIcone('icone1', true)"
              @mouseleave="mudarIcone('icone1', false)"
            />
            CRF - Certificado de Regularidade FGTS do Empregador
          </span>
        </div>
        <b-tooltip
          :target="'icon-web1'"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          {{ tooltipWeb }}
        </b-tooltip>
        <BlocoKYCCRF
          v-if="Object.keys(resultados01).length != 0"
          ref="blocopKYCCRF"
          :resultados="resultados01"
          :listaBlocos="listaBlocos"
          :codigo_agrupador="codigo_agrupador"
          :exibirTootipsConsultas="exibirTootipsConsultas"
          :gerandoPdf="gerandoPdf"        
        />
        <div v-else>
          <Loading class="loading" />
        </div>
        <div class="cabecTab">
          <span class="cabecText titulo-geral">
            <img
              :src="icones.icone4"
              alt=""
              class="icone-aviso"
              id="icon-web4"
              @mouseover="mudarIcone('icone4', true)"
              @mouseleave="mudarIcone('icone4', false)"
            />

            SRF - Certidão Negativa de Tributos (Recente)
          </span>
        </div>
        <b-tooltip
          :target="'icon-web4'"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          {{ tooltipWeb }}
        </b-tooltip>
        <BlocoKYCSRFRecente
          v-if="Object.keys(resultados04).length != 0"
          ref="blocopjKYCSRF"
          :resultados="resultados04"
          :listaBlocos="listaBlocos"
          :codigo_agrupador="codigo_agrupador"
          :exibirTootipsConsultas="exibirTootipsConsultas"
          :gerandoPdf="gerandoPdf"        
        />
        <div v-else>
          <Loading class="loading" />
        </div>
        <div class="cabecTab">
          <span class="cabecText titulo-geral">
            <img
              :src="icones.icone2"
              alt=""
              class="icone-aviso"
              id="icon-web2"
              @mouseover="mudarIcone('icone2', true)"
              @mouseleave="mudarIcone('icone2', false)"
            />

            SRF - Certidão Negativa de Tributos (Histórico)
          </span>
        </div>
        <b-tooltip
          :target="'icon-web2'"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          {{ tooltipWeb }}
        </b-tooltip>
        <BlocoKYCSRFHistorico
          v-if="Object.keys(resultados02).length != 0"
          ref="blocopjKYCSRF"
          :resultados="resultados02"
          :listaBlocos="listaBlocos"
          :codigo_agrupador="codigo_agrupador"
          :exibirTootipsConsultas="exibirTootipsConsultas"
          :gerandoPdf="gerandoPdf"        
        />
        <div v-else>
          <Loading class="loading" />
        </div>
        <div class="cabecTab">
          <span class="cabecText titulo-geral">
            <img
              :src="icones.icone3"
              alt=""
              class="icone-aviso"
              id="icon-web3"
              @mouseover="mudarIcone('icone3', true)"
              @mouseleave="mudarIcone('icone3', false)"
            />

            CNDT - Certidão Negativa de Débitos Trabalhistas
          </span>
        </div>
        <b-tooltip
          :target="'icon-web3'"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          {{ tooltipWeb }}
        </b-tooltip>
        <BlocoKYCCNDT
          v-if="Object.keys(resultados03).length != 0"
          ref="blocopjKYCCNDT"
          :resultados="resultados03"
          :listaBlocos="listaBlocos"
          :codigo_agrupador="codigo_agrupador"
          :exibirTootipsConsultas="exibirTootipsConsultas"
          :gerandoPdf="gerandoPdf"        
        />
        <div v-else>
          <Loading class="loading" />
        </div>
      </div>
    </div>
    <!-- <div v-for="(tt, i) in tooltipsInfoPj1" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div> -->
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapWritableState, mapActions } from "pinia";
import HeaderBlocos from "@/components/Modelos/HeaderBlocos.vue";
import Check from "@/components/SuperBox/Check.vue";
import Loading from "@/components/Loading.vue";

import listaTooltipsModelos from "@/helpers/lista_tooltips_modelos.json";
import BlocoKYCCRF from "@/components/Modelos/BlocoKYC-CRF.vue";
import BlocoKYCSRFHistorico from "@/components/Modelos/BlocoKYC-SRFHistorico.vue";
import BlocoKYCSRFRecente from "@/components/Modelos/BlocoKYC-SRFRecente.vue";
import BlocoKYCCNDT from "@/components/Modelos/BlocoKYC-CNDT.vue";

export default {
  name: "BlocoKYC",

  components: {
    HeaderBlocos,
    BlocoKYCCRF,
    BlocoKYCSRFHistorico,
    BlocoKYCSRFRecente,
    BlocoKYCCNDT,
    Loading,
  },

  props: {
    listaBlocos: Array,
    // resultados01: Object,
    // resultados02: Object,
    // resultados03: Object,
    codigo_agrupador: String,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
    resultadoConsultaAdd: Object,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,

      currentPage: 1,
      linhasPorPagina: 5,

      resultados01: {},
      resultados02: {},
      resultados03: {},
      resultados04: {},

      iconesOriginais: {
        icone1: "/img/modelos/web-crawler-branca.svg",
        icone2: "/img/modelos/web-crawler-branca.svg",
        icone3: "/img/modelos/web-crawler-branca.svg",
        icone4: "/img/modelos/web-crawler-branca.svg",
      },
      iconesHover: {
        icone1: "/img/modelos/web-crawler-roxo.svg",
        icone2: "/img/modelos/web-crawler-roxo.svg",
        icone3: "/img/modelos/web-crawler-roxo.svg",
        icone4: "/img/modelos/web-crawler-roxo.svg",
      },
      icones: {
        icone1: "/img/modelos/web-crawler-branca.svg",
        icone2: "/img/modelos/web-crawler-branca.svg",
        icone3: "/img/modelos/web-crawler-branca.svg",
        icone4: "/img/modelos/web-crawler-branca.svg",
      },

      tooltipWeb:
        "Web Scraping: Esta consulta resulta da mineração 'real time' de dados, tendo seu tempo de retorno e sucesso da consulta suscetível à disponibilidade da fonte de origem.",

      msgErro01: "",
      msgErro02: "",
      msgErro03: "",
    };
  },

  watch: {
    resultadoConsultaAdd: {
      handler: "atualizarResultados",
      deep: true,
      immediate: true,
    },
    // resultados(newValue, oldValue) {
    //   if (Object.keys(newValue).length !== 0) {
    //     this.atualizaAlertas();
    //   }
    // },
  },

  methods: {
    mudarIcone(icone, hover) {
      this.icones[icone] = hover
        ? this.iconesHover[icone]
        : this.iconesOriginais[icone];
    },

    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PJ");
    },

    atualizarResultados() {
      if (this.resultadoConsultaAdd["BOXKYC001"]) {
        this.resultados01 =
          this.resultadoConsultaAdd["BOXKYC001"].atributos || {};

        if (this.resultadoConsultaAdd["BOXKYC001"].erro != null) {
          // this.msgErro01 = this.resultadoConsultaAdd["BOXKYC001"].erro.erro;
          this.resultados01.msgErro =
            this.resultadoConsultaAdd["BOXKYC001"].erro.erro;
        }
        this.abreBloco = false;
      } else {
        this.resultados01 = {};
      }

      if (this.resultadoConsultaAdd["BOXKYC002"]) {
        this.resultados02 =
          this.resultadoConsultaAdd["BOXKYC002"].atributos || {};

        if (this.resultadoConsultaAdd["BOXKYC002"].erro != null) {
          this.resultados02.msgErro =
            this.resultadoConsultaAdd["BOXKYC002"].erro.erro;
        }
        this.abreBloco = false;
      } else {
        this.resultados02 = {};
      }

      if (this.resultadoConsultaAdd["BOXKYC003"]) {
        this.resultados03 =
          this.resultadoConsultaAdd["BOXKYC003"].atributos || {};

        if (this.resultadoConsultaAdd["BOXKYC003"].erro != null) {
          this.resultados03.msgErro =
            this.resultadoConsultaAdd["BOXKYC003"].erro.erro;
        }
        this.abreBloco = false;
      } else {
        this.resultados03 = {};
      }
      if (this.resultadoConsultaAdd["BOXKYC004"]) {
        this.resultados04 =
          this.resultadoConsultaAdd["BOXKYC004"].atributos || {};

        if (this.resultadoConsultaAdd["BOXKYC004"].erro != null) {
          this.resultados04.msgErro =
            this.resultadoConsultaAdd["BOXKYC004"].erro.erro;
        }
        this.abreBloco = false;
      } else {
        this.resultados04 = {};
      }

      this.abreBloco = true;
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    compararValores(str1, str2) {
      const removerAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      const prepararString = (str) => {
        return str.trim().toLowerCase();
      };

      return (
        prepararString(removerAcentos(str1)) ===
        prepararString(removerAcentos(str2))
      );
    },

    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },

    formatarTexto(texto) {
      if (texto != null && texto != "-") {
        if (this.ocultarDados) {
          texto = texto.replace(/[^ ]/g, "*");
        }
      }

      return texto;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    paginaAnterior() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    proximaPagina() {
      if (this.currentPage < this.totalPaginas) {
        this.currentPage++;
      }
    },

    irParaPagina(pagina) {
      this.currentPage = pagina;
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBoxPJ, ["ativaAlerta"]),

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  created() {},

  computed: {
      blocoKYC() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "KYC & Due Diligence"
      );
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    tooltipsInfoPj1() {
      return listaTooltipsModelos.bloco_info_pj_1;
    },

    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_") &&
          !chave.includes("status_cadastral")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      usuarioLogado: "usuarioLogado",
    }),
  },

  mounted() {
    this.atualizarResultados();
  },
};
</script>
<style lang="scss" scoped>
.body {
  align-items: center;
}

.aviso {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px;
  width: 80%;
  text-align: center;
  margin: 10px 0 0 0;
}

.row-info {
  width: 100%;
  margin-top: 5px;
  margin-left: 0px;
  display: flex;
  max-width: 950px;
  justify-content: flex-start;
  cursor: default;
}
.col-info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cabecTab {
  display: flex;
  width: 100%;
  max-width: 950px;

  line-height: 14px;
}

.cabecText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  background-color: #2f75b5;
  color: white;
  // @include flex-center(column);
  margin: 20px 0 5px 0;
}

.icone-aviso {
  height: 25px;
  width: 25px;
  position: absolute;
  left: 20px;
  transition: 0.5s;
}

.info-col {
  padding: 0;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.paginas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
}

.titulo {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #0070c0;
  display: flex;
  align-items: flex-end;
  padding: 0 5px;
}

.titulo-box {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  height: 40px;
  text-align: center;

  @include flex-center(column);

  &.todo {
    height: 100%;
  }
}

table {
  border-spacing: 5px;
  border-collapse: separate;
  transform: translateY(5px);
}

.cabec-box {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  height: 40px;
  text-align: center;
}

.results-box {
  height: 40px;
  text-align: center;
  border: solid 1px #0070c0;
  border-radius: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  word-break: break-word;
  line-height: 12px;
}
.results {
  height: 40px;
  text-align: center;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  word-break: break-word;
  line-height: 12px;
}

.pag-botao {
  user-select: none;
  color: #0070c0;
  cursor: pointer;
}

.box-pagina {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 11px;
  font-weight: 300;
  line-height: 16px;
  color: #0070c0;
}

.botao-pagina {
  width: 10px;
  height: 10px;
  border: 2px solid #0070c0;
  border-radius: 50%;
  background-color: transparent;
  margin: 0 2px;
  cursor: pointer;
  padding: 0;
}

.botao-pagina.pagina-ativa {
  background-color: #0070c0;
}

.legenda-pagina {
  position: absolute;
  transform: translateY(15px);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
