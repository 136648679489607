<template>
  <div class="content">
    <div class="row-info">
      <div class="titulo sb-5 sb-right" :id="'BlocoKYC2-fonte'">Fonte</div>
      <div class="titulo sb-1 sb-right" :id="'BlocoKYC2-retorno'">
        Retorno da consulta
      </div>
      <div
        class="titulo sb-1 sb-right"
        :id="'BlocoKYC2-emissao'"
        v-if="Object.keys(this.resultados).length != 1"
      >
        Emissão da certidão
      </div>
      <div
        class="titulo sb-2"
        :id="'BlocoKYC2-detalhes'"
        v-if="resultados.boxconnis004_code != 200"
      >
        Detalhe de <br />retorno da consulta
      </div>
    </div>
    <div class="row-info">
      <div class="results sb-5 sb-right">
        Secretaria Especial da Receita Federal do Brasil
      </div>
      <div class="results sb-1 sb-right">
        <Check :valor="Object.keys(this.resultados).length != 1" />
      </div>
      <div
        class="results sb-1 sb-right"
        v-if="Object.keys(this.resultados).length != 1"
      >
        <Check
          :valor="
            resultados.boxconnis004_conseguiu_emitir_certidao_negativa != null
          "
        />
      </div>
      <div
        class="results sb-5 sb-right"
        v-if="resultados.boxconnis004_code != 200"
        >
        {{ msgAlerta }}
      </div>
    </div>
    <div
      class="row-info sb-top2"
      v-if="Object.keys(this.resultados).length != 1"
    >
      <div class="titulo-box escuro sb-4 sb-right">Código de Controle</div>
      <div class="titulo-box escuro sb-3 sb-right">Tipo</div>
      <div class="titulo-box escuro sb-1 sb-right">Data de Emissão</div>
      <div class="titulo-box escuro sb-1 sb-right">Data de Validade</div>
      <div class="titulo-box escuro sb-1 sb-right">Situação</div>
      <div class="titulo-box escuro sb-1 sb-right" id="pgfn">Débitos PGFN</div>
      <div class="titulo-box escuro sb-1 sb-right" id="rfb">Débitos RFB</div>
    </div>
    <div
      class="row-info sb-top"
      v-if="Object.keys(this.resultados).length != 1"
    >
      <div class="results sb-4 sb-right">
        {{ resultados.boxconnis004_certidao_codigo }}
      </div>
      <div class="results sb-3 sb-right">
        {{ resultados.boxconnis004_comprovante_tipo }}
      </div>
      <div class="results sb-1 sb-right">
        {{ formatarData(resultados.boxconnis004_emissao_data) }}
      </div>
      <div class="results sb-1 sb-right">
        {{ formatarData(resultados.boxconnis004_validade) }}
      </div>
      <div class="results sb-1 sb-right">
        {{ resultados.boxconnis004_situacao }}
      </div>
      <div class="results sb-1 sb-right">
        <Check :valor="resultados.boxconnis004_debitos_pgfn" />
      </div>
      <div class="results sb-1 sb-right">
        <Check :valor="resultados.boxconnis004_debitos_rfb" />
      </div>
    </div>
    <div
      class="row-info sb-top"
      v-if="Object.keys(this.resultados).length != 1"
    >
      <div class="sb-4 sb-right"></div>
      <div class="titulo-box escuro sb-3 sb-right">
        Informações Complementares
      </div>
      <div class="results sb-5 sb-right">
        {{ resultados.boxconnis004_mensagem }}
      </div>
    </div>
    <div
      class="row-info sb-top"
      v-if="Object.keys(this.resultados).length != 1"
    >
      <div class="sb-4 sb-right"></div>
      <div class="titulo-box escuro sb-3 sb-right">Observações</div>
      <div class="results sb-5 sb-right">
        {{ resultados.boxconnis004_observacoes }}
      </div>
    </div>

    <div
      class="row-info"
      style="justify-content: center"
      v-if="Object.keys(this.resultados).length == 1"
    >
      <div class="card aviso">
        {{ resultados.msgErro }}
      </div>
    </div>
    <b-tooltip
      v-if="exibirTootipsConsultas"
      :target="'pgfn'"
      triggers="hover"
      placement="bottom"
      variant="dark"
      class="tooltipTexts"
      boundary="window"
    >
      {{ tooltips.PGFN }}
    </b-tooltip>
    <b-tooltip
      v-if="exibirTootipsConsultas"
      :target="'rfb'"
      triggers="hover"
      placement="bottom"
      variant="dark"
      class="tooltipTexts"
      boundary="window"
    >
      {{ tooltips.RFB }}
    </b-tooltip>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapWritableState, mapActions } from "pinia";
import Check from "@/components/SuperBox/Check.vue";
import listaTooltipsModelos from "@/helpers/lista_tooltips_modelos.json";
import listaAlertasKYC from "@/helpers/lista_alertas_KYC.json";

export default {
  name: "BlocoKYCSRFRecente",

  components: {
    Check,
  },

  props: {
    listaBlocos: Array,
    resultados: Object,
    codigo_agrupador: String,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,

      tooltips: {
        PGFN: "Dívidas vencidas e não pagas que estão incluídas na lista de devedores da União (Procuradoria-Geral da Fazenda Nacional - PGFN), também conhecida como Dívida Ativa",
        RFB: "Débitos de uma empresa junto à Receita Federal do Brasil (RFB) referentes à impostos não pagos ou recolhidos indevidamente.",
      },
    };
  },

  watch: {},

  methods: {
    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    formatarData(dataStr) {
      if (dataStr) {
        const data = new Date(dataStr);

        const dia = String(data.getUTCDate()).padStart(2, "0");
        const mes = String(data.getUTCMonth() + 1).padStart(2, "0");
        const ano = data.getUTCFullYear();

        const horas = String(data.getUTCHours()).padStart(2, "0");
        const minutos = String(data.getUTCMinutes()).padStart(2, "0");
        const segundos = String(data.getUTCSeconds()).padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      }
      return dataStr;
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBoxPJ, ["ativaAlerta"]),

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  created() {},

  computed: {
    blocoKYC() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "KYC & Due Diligence"
      );
    },
    headerDicionario() {
      return {
        boxconnis002_lista_codigo_controle: "Código de controle",
        boxconnis002_lista_tipo: "Tipo",
        boxconnis002_lista_data_emissao: "Data-hora emissão",
        boxconnis002_lista_data_validade: "Data de validade",
        boxconnis002_lista_situacao: "Situação",
        boxconnis002_lista_informacoes_complementares:
          "Informações complementares",
      };
    },

    msgAlerta() {
      return (
        listaAlertasKYC[this.resultados.boxconnis004_code] ||
        "Código desconhecido"
      );
    },

    headerFormatado() {
      return Object.keys(this.headerDicionario).map(
        (key) => this.headerDicionario[key]
      );
    },

    resultadosLinhas() {
      const valoresPorLinha = Object.keys(this.headerDicionario)
        .filter((chave) => this.resultadoTratado[chave])
        .map((chave) =>
          this.resultadoTratado[chave]
            .split(",")
            .map((v) => v.replace(/"/g, "").trim())
        );

      const totalLinhas = valoresPorLinha[0]?.length || 0;

      return Array.from({ length: totalLinhas }, (_, rowIndex) =>
        valoresPorLinha.map((coluna) => coluna[rowIndex] || "-")
      );
    },

    tabelaPaginada() {
      const start = (this.currentPage - 1) * this.linhasPorPagina;
      const end = start + this.linhasPorPagina;

      const paginaAtual = this.resultadosLinhas.slice(start, end);

      while (paginaAtual.length < this.linhasPorPagina) {
        paginaAtual.push(
          Array(this.resultadosLinhas[0]?.length || 0).fill("-")
        );
      }

      return paginaAtual;
    },

    totalPaginas() {
      return Math.ceil(this.resultadosLinhas.length / this.linhasPorPagina);
    },

    paginasVisiveis() {
      const total = this.totalPaginas;

      if (total <= 5) {
        return Array.from({ length: total }, (_, i) => i + 1);
      }

      let startPage = Math.max(1, this.currentPage - 2);
      let endPage = Math.min(total, startPage + 4);

      if (endPage === total) {
        startPage = Math.max(1, total - 4);
      }

      return Array.from({ length: 5 }, (_, i) => startPage + i);
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    tooltipsInfoPj1() {
      return listaTooltipsModelos.bloco_info_pj_1;
    },

    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_") &&
          !chave.includes("status_cadastral")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      usuarioLogado: "usuarioLogado",
    }),
  },

  mounted() {
    // this.atualizaAlertas();
  },
};
</script>
<style lang="scss" scoped>
.body {
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row-info {
  width: 100%;
  margin-top: 5px;
  margin-left: 0px;
  display: flex;
  max-width: 950px;
  justify-content: flex-start;
  cursor: default;
}

.aviso {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px;
  width: 80%;
  text-align: center;
  margin: 10px 0 0 0;
  align-self: center;
}

.col-info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cabecTab {
  display: flex;
  width: 100%;
  max-width: 950px;

  line-height: 14px;
}

.cabecText {
  display: flex;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  background-color: #2f75b5;
  color: white;
  @include flex-center(column);
  margin: 20px 0 5px 0;
}

.info-col {
  padding: 0;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.paginas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
}

.titulo {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #0070c0;
  display: flex;
  align-items: flex-end;
  padding: 0 5px;
}

.titulo-box {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  height: 40px;
  text-align: center;

  @include flex-center(column);

  &.escuro {
    background-color: #b4c6e7;
  }

  &.todo {
    height: 100%;
  }
}

table {
  border-spacing: 5px;
  border-collapse: separate;
  transform: translateY(5px);
}

.cabec-box {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  height: 40px;
  text-align: center;

  &.desativado {
    background-color: #d9d9d9 !important;
  }
}

.results-box {
  height: 40px;
  text-align: center;
  border: solid 1px #0070c0;
  border-radius: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 3px;
  word-break: break-word;
  line-height: 12px;

  &.borda-desativada {
    border: solid 1px #999999 !important;
    color: #999999;
  }
}
.results {
  height: 40px;
  text-align: center;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 2px;
  word-break: break-word;
  line-height: 12px;
}

.pag-botao {
  user-select: none;
  color: #0070c0;
  cursor: pointer;
}

.box-pagina {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 11px;
  font-weight: 300;
  line-height: 16px;
  color: #0070c0;
}

.botao-pagina {
  width: 10px;
  height: 10px;
  border: 2px solid #0070c0;
  border-radius: 50%;
  background-color: transparent;
  margin: 0 2px;
  cursor: pointer;
  padding: 0;
}

.botao-pagina.pagina-ativa {
  background-color: #0070c0;
}

.legenda-pagina {
  position: absolute;
  transform: translateY(15px);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
