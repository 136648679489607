var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"col-info"},[_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"titulo sb-4 sb-right",attrs:{"id":'BlocoKYC1-fonte'}},[_vm._v("Fonte")]),_c('div',{staticClass:"titulo sb-1 sb-right",attrs:{"id":'BlocoKYC1-cnpj'}},[_vm._v(" Retorno da consulta ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"results sb-4 sb-right"},[_vm._v("Caixa Econômica Federal")]),_c('div',{staticClass:"results sb-1 sb-right"},[_c('Check',{attrs:{"valor":Object.keys(this.resultados).length != 1}})],1)]),(Object.keys(this.resultados).length != 1)?_c('div',{staticClass:"row-info sb-top"},[_vm._m(0),_c('div',{staticClass:"col-info sb-1 sb-right"},[_c('div',{staticClass:"titulo-box",attrs:{"id":'BlocoKYC1-numero'}},[_vm._v("Número")]),_c('div',{staticClass:"titulo-box sb-top",attrs:{"id":'BlocoKYC1-situacao'}},[_vm._v(" Situação ")]),_c('div',{staticClass:"titulo-box sb-top",attrs:{"id":'BlocoKYC1-inicio'}},[_vm._v(" Início da validade ")]),_c('div',{staticClass:"titulo-box sb-top",attrs:{"id":'BlocoKYC1-fim'}},[_vm._v(" Fim da validade ")]),_c('div',{staticClass:"titulo-box sb-top",attrs:{"id":'BlocoKYC1-vigencia'}},[_vm._v(" Vigência da validade ")])]),_c('div',{staticClass:"col-info sb-3"},[_c('div',{staticClass:"results"},[_vm._v(" "+_vm._s(_vm.resultadoTratado.boxconnis001_crf)+" ")]),_c('div',{staticClass:"results sb-top"},[_vm._v(" "+_vm._s(_vm.resultadoTratado.boxconnis001_situacao)+" ")]),_c('div',{staticClass:"results sb-top"},[_vm._v(" "+_vm._s(_vm.formatarData(_vm.resultados.boxconnis001_validade_inicio_data))+" ")]),_c('div',{staticClass:"results sb-top"},[_vm._v(" "+_vm._s(_vm.formatarData(_vm.resultados.boxconnis001_validade_fim_data))+" ")]),_c('div',{staticClass:"results sb-top"},[_c('Check',{attrs:{"valor":_vm.verificaInvervalo(
                  _vm.resultados.boxconnis001_validade_inicio_data,
                  _vm.resultados.boxconnis001_validade_fim_data
                )}})],1)])]):_vm._e()]),(Object.keys(this.resultados).length != 1)?_c('div',{staticClass:"col-info"},[_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"titulo text-center",staticStyle:{"width":"100%","padding":"10px"},attrs:{"id":'BlocoKYC1-historico'}},[_vm._v(" Histórico de Registros ")])]),_c('div',{staticClass:"row-info"},[_c('table',[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.headerFormatado),function(header){return _c('th',{key:header,staticClass:"cabec-box"},[_vm._v(" "+_vm._s(header)+" ")])})],2)]),_c('tbody',_vm._l((_vm.tabelaPaginada),function(row,index){return _c('tr',{key:index},[_c('td',{staticClass:"cabec-box sb-1",class:{
                  desativado: row[3] == '-',
                }},[_vm._v(" "+_vm._s((_vm.currentPage - 1) * _vm.linhasPorPagina + index + 1)+" ")]),_vm._l((row),function(value,key){return _c('td',{key:key,staticClass:"results-box sb-1",class:{
                  'sb-3': key == 3,
                  'borda-desativada': value == '-',
                }},[(key != 3)?_c('span',[_vm._v(" "+_vm._s(_vm.formatarData(value))+" ")]):_c('span',[_vm._v(" "+_vm._s(value)+" ")])])})],2)}),0)])]),_c('div',{staticClass:"box-info sb-top"},[_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"paginas"},[_c('span',{staticClass:"material-symbols-outlined pag-botao",class:{ disabled: _vm.currentPage === 1 },on:{"click":function($event){return _vm.irParaPagina(1)}}},[_vm._v(" keyboard_double_arrow_left ")]),_c('span',{staticClass:"material-symbols-outlined pag-botao",class:{ disabled: _vm.currentPage === 1 },on:{"click":_vm.paginaAnterior}},[_vm._v(" chevron_left ")]),_vm._l((_vm.paginasVisiveis),function(pagina){return _c('span',{key:pagina,staticClass:"box-pagina"},[_c('button',{class:[
                  'botao-pagina',
                  { 'pagina-ativa': pagina === _vm.currentPage },
                ],on:{"click":function($event){return _vm.irParaPagina(pagina)}}}),_c('span',{staticClass:"legenda-pagina"},[_vm._v(_vm._s(pagina))])])}),_c('span',{staticClass:"material-symbols-outlined pag-botao",class:{ disabled: _vm.currentPage === _vm.totalPaginas },on:{"click":_vm.proximaPagina}},[_vm._v(" chevron_right ")]),_c('span',{staticClass:"material-symbols-outlined pag-botao",class:{ disabled: _vm.currentPage === _vm.totalPaginas },on:{"click":function($event){return _vm.irParaPagina(_vm.totalPaginas)}}},[_vm._v(" keyboard_double_arrow_right ")])],2)])])]):_vm._e()]),(Object.keys(this.resultados).length == 1)?_c('div',{staticClass:"row-info",staticStyle:{"justify-content":"center"}},[_c('div',{staticClass:"card aviso"},[_vm._v(" "+_vm._s(_vm.resultados.msgErro)+" ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-info sb-1 sb-right"},[_c('div',{staticClass:"titulo-box todo"},[_vm._v("Informações sobre CRF")])])
}]

export { render, staticRenderFns }