<template>
  <div class="content">
    <div class="dropdown">
      <div class="body-param">
        <div class="title-param">Informações sobre a consulta</div>
        <div class="row">
          <div class="box-info">
            <div class="box-tit">Data e hora da consulta</div>
            <div class="info">
              {{ formatarData(resultados.data_transacao_brt) }}
            </div>
          </div>
          <div class="box-info">
            <div class="box-tit">Código da consulta</div>
            <div class="info" style="justify-content: space-between">
              {{ codAgr }}
              <button class="copy" id="copiar-codAgr" v-clipboard:copy="codAgr">
                <span class="material-symbols-outlined copybtn"
                  >content_copy</span
                >
                <b-tooltip
                  v-if="exibirTootipsConsultas"
                  :target="'copiar-codAgr'"
                  triggers="hover"
                  placement="bottom"
                  variant="dark"
                  class="tooltipTexts"
                  boundary="window"
                >
                  Copiar código da consulta
                </b-tooltip>
              </button>
            </div>
          </div>
        </div>
        <div class="title-param" style="margin-top: 10px">
          Informações sobre os inputs
        </div>
        <!-- {{ resultados.parametros }} -->
        <div class="box-itens">
          <div
            class="itens"
            v-for="item in respostaParametros"
            :key="item.nome_api"
          >
            <span class="texto"> {{ item.nome_amigavel }}: </span>
            <span
              class="valor"
              v-if="
                item.valor != '' &&
                item.nome_amigavel != 'CPF' &&
                item.nome_amigavel != 'CNPJ'
              "
            >
              {{ item.valor }}
            </span>
            <span
              class="valor"
              v-if="item.valor != '' && item.nome_amigavel == 'CNPJ'"
            >
              {{ formatarCNPJ(item.valor) }}
            </span>
            <span
              class="valor"
              v-if="item.valor != '' && item.nome_amigavel == 'CPF'"
            >
              {{ formatarCPF(item.valor) }}
            </span>
            <span class="valor" v-if="item.valor == ''"> -- </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { userPreferenceStore } from "@/stores/userPreference";
import { mascaras } from "@/helpers/mascaras";

import inputsModelos from "@/helpers/inputs_modelos.json";

export default {
  name: "BlocoParametros",

  components: {},

  props: {
    codAgr: String,
    resultados: Object,
    ocultarDados: Boolean,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
      respostaParametros: [],
    };
  },

  watch: {},

  methods: {
    verificaParametros() {
      const chaveExistente = Object.keys(this.listaInputs).includes(
        this.resultados.parametros.cod_ref
      );

      let listaUtilizada;
      if (chaveExistente) {
        const listaSelecionada =
          this.listaInputs[this.resultados.parametros.cod_ref];
        const listaPadrao = this.listaInputs["Padrao"];

        let nomesApiEncontrados = new Set(
          listaSelecionada.map((obj) => obj.nome_api)
        );

        const listaPadraoFiltrada = listaPadrao.filter((obj) => {
          const nomeApi = obj.nome_api;
          const jaEncontrado = nomesApiEncontrados.has(nomeApi);
          if (!jaEncontrado) {
            nomesApiEncontrados.add(nomeApi);
            return true;
          }
          return false;
        });

        listaUtilizada = listaSelecionada.concat(listaPadraoFiltrada);
      } else {
        listaUtilizada = this.listaInputs["Padrao"];
      }

      let listaParametros = Object.entries(this.resultados.parametros);

      for (const item of listaParametros) {
        const listaCorrespondente = listaUtilizada.find(
          (lista) => lista.nome_api === item[0]
        );

        if (listaCorrespondente !== undefined) {
          listaCorrespondente.valor = item[1];

          if (this.usuarioLogado.Empresa == "StepWise") {
            if (listaCorrespondente.nome_api === "data_ref") {
              listaCorrespondente.visivel = true;
              listaCorrespondente.Obrigatorio = false;
            }
          }
          if (listaCorrespondente.visivel) {
            this.respostaParametros.push(listaCorrespondente);
          }
        } else {
          if (item[0] != "cod_ref" && item[0] != "modo_resultado") {
            const objetoInput = {
              nome_amigavel: item[0],
              valor: item[1],
            };
            this.respostaParametros.push(objetoInput);
          }
        }
      }
    },

    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const date = new Date(dataString);
        const formattedDate = `${this.padZero(date.getDate())}/${this.padZero(
          date.getMonth() + 1
        )}/${date.getFullYear()}`;
        const formattedTime = `${this.padZero(date.getHours())}:${this.padZero(
          date.getMinutes()
        )}:${this.padZero(date.getSeconds())}`;
        return `${formattedDate} | ${formattedTime}`;
      } else {
        return dataString;
      }
    },
    padZero(value) {
      return value < 10 ? `0${value}` : value;
    },

    formatarCPF(valor) {
      const cpf = mascaras.cpf(valor);
      if (this.ocultarDados) {
        return cpf.replace(/(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/, "***.$2.$3-**");
      } else {
        return cpf;
      }
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },
  },

  computed: {
    listaInputs() {
      return inputsModelos;
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
    }),
  },

  created() {},
  async mounted() {
    this.verificaParametros();
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-left: 0 !important;
}

.body-param {
  border-top: dotted 1px #0070c0;
  margin: 40px 0 0 5px;
  overflow: hidden;
  width: 960px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 18px;
  cursor: default;
}

.title-param {
  width: 100%;
  text-transform: uppercase;
  color: #0070c0;
  font-weight: 500;
  font-size: 14px;
  margin: 5px 0 0 0;
  cursor: default;
}
.box-info {
  margin: 5px 5px 5px 0;
  width: 200px;
}
.box-tit {
  margin: 0 0 0 5px !important;
  font-size: 12px;
  color: #0070c0;
  width: fit-content;
  cursor: default;
  line-height: 18px;
}

.info {
  height: 37px;
  width: 100%;
  margin: 0;
  border: solid 1px #0070c0;
  border-radius: 10px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
  line-height: 18px;
}

.box-itens {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  line-height: 18px;
}
.itens {
  margin: 5px 0 0 0;
  font-size: 14px;
  .texto {
    color: #0070c0;
  }
}
.copy {
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
  margin: 0;
  border-radius: 25%;
  padding: 5px 0 0 0;
}
.copy:hover {
  transform: scale(1.05);
}
.copy:active {
  transform: scale(0.9);
}
.copybtn {
  color: rgba(148, 148, 148, 0.7);
  transition: 1s all ease;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
